import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        collapsed: Boolean,
        disabled: Boolean,
        title: {
            type: [String, Object],
            default: ''
        }
    }
});
